import React from "react";
import { graphql } from "gatsby";
import { micromark } from "micromark";

import Layout from "../components/layout";

const Contact = (props: {
  data: { allMarkdownRemark: { nodes: { frontmatter: any }[] } };
  pageContext: { language: string | number };
}) => {
  const data = props.data.allMarkdownRemark.nodes[0].frontmatter;
  const { title, body } = data[props.pageContext.language];

  const bodyHTML = micromark(body);

  return (
    <Layout>
      <h1>{title}</h1>
      <div dangerouslySetInnerHTML={{ __html: bodyHTML }}></div>
    </Layout>
  );
};

export const pageQuery = graphql`
  query {
    allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/(contact)/" } }) {
      nodes {
        frontmatter {
          title
          en {
            title
            body
          }
          cy {
            title
            body
          }
        }
      }
    }
  }
`;

export default Contact;
